<template lang="html">
    <main-layout :settings-breadcrumbs="settingsBreadcrumbs">
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'Address'"
                @validate="validate"
                @close-modal="showModal=false"
                @confirm="confirm"
            >
                <template #headerIcon>
                    <home />
                </template>
                <template #form>
                    <div class="profile-info__form">
                        <div class="profile-info__form-group">
                            <div class="profile-info__form-row">
                                <div class="profile-info__col100">
                                    <Address
                                        @checkRequired="required = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/profile/ProfileAddress/Rules'
import { formMessages } from '@/validation/profile/ProfileAddress/Messages'

import { eventBus } from '@/helpers/event-bus/'
import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import Home from 'mdi-vue/Home'
import Address from '@/components/account-settings/ProfileAddress/Address';
import {
    mapWriterFields
} from '@/store/modules/writer';
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_WRITER_UPDATE_ADDRESS
} from '@/store/modules/writer/action-types';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        Address,
        ProfileFormLayout,
        Home
    },
    metaInfo: {
        title: 'Adress'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            showModal: false,
            required: null
        }
    },
    validations: {
        ...validationRules
    },
    computed: {
        ...mapWriterFields([
            'writer_data.address',
            'writer_data.city',
            'writer_data.zip',
            'writer_data.country',
            'writer_data.region'
        ])
    },
    mounted() {
        eventBus.$on('validate', () => {
            this.validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('validate')
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions({
            updateAddress: ACTION_WRITER_UPDATE_ADDRESS
        }),
        async submit() {
            try {
                await this.updateAddress({
                    address: this.address,
                    city: this.city,
                    zip: this.zip,
                    country: this.country,
                    region: this.region
                })
                eventBus.$emit('showSnackBar', 'Your address has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>
