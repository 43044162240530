<template lang="html">
    <div class="profile-info__form-group">
        <div class="adress-wrapper">
            <div class="profile-info__col50 mt-0 profile-info__adress">
                <form-input
                    v-model="$v.address.$model"
                    outlined
                    legend="Address"
                    :field.sync="address"
                    :errors="validationMessage($v.address)"
                    :is-valid="$v.address.$dirty && !$v.address.$anyError"
                    @input.native="$v.address.$touch()"
                    @blur.native="$v.address.$touch()"
                />
            </div>
            <div class="profile-info__col50 profile-info__adress">
                <form-input
                    v-model="city"
                    legend="City"
                    :field.sync="city"
                    :errors="validationMessage($v.city)"
                    :is-valid="$v.city.$dirty && !$v.city.$anyError"
                    @input.native="$v.city.$touch()"
                    @blur.native="$v.city.$touch()"
                />
            </div>
            <div class="profile-info__col50 profile-info__adress">
                <form-input
                    v-model="zip"
                    legend="ZIP"
                    :field.sync="zip"
                    :errors="validationMessage($v.zip)"
                    :is-valid="$v.zip.$dirty && !$v.zip.$anyError"
                    @input.native="$v.zip.$touch()"
                    @blur.native="$v.zip.$touch()"
                />
            </div>
            <div class="profile-info__col50 profile-info__adress">
                <form-select-countries
                    v-model="$v.country.$model"
                    :country="country"
                    label="Country"
                    tabindex="0"
                    :field.sync="country"
                    :errors="validationMessage($v.country)"
                    :is-valid="!$v.country.$anyError && !$v.country.$invalid"
                    @input.native="$v.country.$touch()"
                    @blur.native="$v.country.$touch()"
                />
            </div>
            <div
                v-show="regionsLength > 0"
                class="profile-info__col50 profile-info__adress"
            >
                <form-select-region
                    v-model="$v.region.$model"
                    :region="region"
                    :country="country"
                    label="Region"
                    tabindex="0"
                    :field.sync="region"
                    :errors="validationMessage($v.region)"
                    :is-valid="!$v.region.$anyError && !$v.region.$invalid"
                    @input.native="$v.region.$touch()"
                    @blur.native="$v.region.$touch()"
                    @checkRequired="checkRequired"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
// Vuelidate
import { validationMixin } from 'vuelidate'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/profile/ProfileAddress/Rules'
import { formMessages } from '@/validation/profile/ProfileAddress/Messages'

// VUEX
import {
    mapWriterFields
} from '@/store/modules/writer';

export default {
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            regionsLength: 0,
            loading: false
        }
    },
    computed: {
        ...mapWriterFields([
            'writer_data.address',
            'writer_data.city',
            'writer_data.zip',
            'writer_data.country',
            'writer_data.region'
        ])
    },
    mounted() {
        eventBus.$on('saveSettings', this.saveSettings)
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        saveSettings() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.validate()
                return
            }
            eventBus.$emit('validate', true)
        },
        checkRequired(val) {
            this.regionsLength = val
        }
    }
}
</script>

<style lang="scss">
// @import '$stylepath/styles-sitewide/components/_flags.scss';
.adress-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 20px;
    margin-bottom: 20px;
}

.phone__form-col_btn {
    display: flex;
}
.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}
.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}

.flag-icon:before {
    content: "\00a0";
}
.profile-info__col50.mt-0 {
    margin-top: 0 !important;
}
</style>
